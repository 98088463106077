import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { ComMain } from '@components/index'
import PdfLink from '../assets/images/map/map.pdf'
import PdfLink2 from '../assets/images/map/gallery.pdf'

const MapPage = () => {

  return (
    <main className='mainMap'>
      {/* CONTENTS */}

      <ComMain
        ttl="Access Map"
        subttl="現地案内図"
        caption=""
      >
      </ComMain>

      <section className='sec-map'>
        <div className="c-content">

          <div className="map-wrap">
            <div className="map-box">
              <div className="map">
                <StaticImage
                  src="../assets/images/map/map.jpg"
                  alt=""
                />
              </div>
            </div>

            <div className="map-com-link-box">
              <a href={PdfLink} className="link" target="_blank" rel="noopener noreferrer">
                <span>印刷する</span>
              </a>
              <a href="https://goo.gl/maps/4vNT1ho6codWaXsF6" className="link" target="_blank" rel="noopener noreferrer">
                <span>Google Map</span>
              </a>
            </div>
          </div>

        </div>
      </section>



      <section className='sec-gallery'>
        <div className="c-content">

          <div className="gallery-wrap">
            <div className="ttl-box">
              <p className="ttl">マンションギャラリーまでのルート</p>
            </div>

            <div className="flex-box">
              <div className="left">
              <StaticImage
                  src="../assets/images/map/gallery_left.png"
                  alt=""
                />
              </div>
              <div className="center">
              <StaticImage
                  src="../assets/images/map/gallery_center.png"
                  alt=""
                />
              </div>
              <div className="right">
              <StaticImage
                  src="../assets/images/map/gallery_right.png"
                  alt=""
                />
              </div>
            </div>

            <div className="data-box">
              <div className="adress">
                <p className="p1">マンションギャラリー所在地</p>
                <p className="p2">神奈川県小田原市栄町２丁目７－２５</p>
              </div>
              <div className="adress">
                <p className="p1">小田原栄町駐車場</p>
                <p className="p2">〒250-0011 神奈川県小田原市栄町２丁目７−２３</p>
              </div>

              <p className="txt">マンションギャラリーまで車でお越しのお客様は、<br />「小田原栄町駐車場」をご利用ください。</p>
              <p className="txt">また、マンションギャラリー・駐車場周辺は<br />下記日時、歩行者天国により通行規制がございます。</p>
              <p className="date">平日 12:00〜13:00／日・祝日 12:00〜18:00</p>
              <p className="txt">車でお越しのお客様は緑色点線で記載の、<br className='_sp' />迂回ルートよりお越しください。</p>
            </div>

            <div className="note-box">
              <p className="c-note">※満車の場合はご容赦ください。ご来場の際はなるべく、公共交通機関のご利用をお願い申し上げます。</p>
            </div>

            <div className="map-com-link-box">
              <a href={PdfLink2} className="link" target="_blank" rel="noopener noreferrer">
                <span>印刷する</span>
              </a>
              <a href="https://goo.gl/maps/ebB5XyuhRCjtMVHm7" className="link" target="_blank" rel="noopener noreferrer">
                <span>Google Map</span>
              </a>
            </div>
          </div>

        </div>
      </section>


      {/* CONTENTS */}
    </main>
  )
}
export default MapPage